import { createStore } from 'vuex'

// 引入vuex持久化方法createPersistedState
import createPersistedState from 'vuex-persistedstate'
import app from './modules/app'
import getters from './getters.js'

export default createStore({
  state: {},
  getters: getters,
  mutations: {},
  actions: {},
  modules: { app },
  plugins: [
    createPersistedState({
      // 保存到 localStorage 中的 key
      key: 'ident_patient_h5',
      // 需要保存的模块
      paths: ['app']
    })
  ]
})
