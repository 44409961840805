// import store from '@/store'
import axios from 'axios'
import { Dialog, Toast } from 'vant'
import store from '@/store'
import qs from 'qs'
import router from '@/router'

/**
 * 创建axios实例
 */
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000
})

const whiteList = [
  '/account',
  '/api/sys/oauth/token',
  '/wx/his/base/list-clinics-accountId',
  '/clinic_info',
  'oauth2',
  'wx/user/',
  '/wx/his/base/list-workers/',
  '/wx/his/base/list-clinics'
]
const notVerifyAuth = (url = '') => {
  return whiteList.some((item) => url.includes(item))
}

/**
 * 请求拦截器
 */
request.interceptors.request.use(
  (config) => {
    if (store.getters.token.access_token && !notVerifyAuth(config.url)) {
      config.headers.Authorization = `Bearer ${store.getters.token.access_token}`
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

async function goLogin() {
  setTimeout(() => {
    Dialog.confirm({
      title: '您未绑定手机号',
      message: '为了提供更好的服务和保护您的账号安全，请绑定您的手机号。',
      confirmButtonText: '去绑定',
      cancelButtonText: '暂不绑定'
    })
      .then(() => {
        router.push({
          path: `/my/bind/${router.currentRoute.value.params.path}`,
          query: {
            returnUrl: router.currentRoute.value.fullPath
          }
        })
      })
      .catch(() => {
        if (window.history.state.back) {
          router.back()
        } else {
          router.replace(router.currentRoute.value.fullPath)
        }
      })
  }, 100)
}

/**
 * 响应拦截器
 */
request.interceptors.response.use(
  (res) => {
    if (res.data.code > 300) {
      Toast.fail({
        message: res.data.message,
        duration: 1000,
        onClose() {
          if (res.data.message === '没有绑定门诊') {
            router.push(`/home/${router.currentRoute.value.params.path}`)
          }
        }
      })

      return Promise.reject(res.data.message)
    }
    return res.data
  },
  async (error) => {
    if (error.response.status === 401) {
      const token = store.getters.token
      if (token.access_token && token.refresh_token) {
        // 有token
        try {
          const res = await axios({
            method: 'post',
            url: 'api/sys/oauth/token',
            auth: {
              username: 'app',
              password: 'app'
            },
            data: qs.stringify({
              grant_type: 'refresh_token',
              refresh_token: token.refresh_token
            })
          })
          // 刷新成功
          store.commit('app/changeToken', res.data)
          // 重新发送请求
          return request(error.config)
        } catch {
          goLogin()
          return Promise.reject(error)
        }
      } else {
        goLogin()
        return Promise.reject(error)
      }
    } else {
      // 服务器正常返回，但是接口报错了
      Toast.fail(error.response.data.message)
      return Promise.reject(error)
    }
  }
)

export default request
