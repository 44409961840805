import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'index',
  props: {
    area: {
      type: String,
      default: 'bottom'
    },
    bgColor: {
      type: String,
      default: '#ffffff'
    }
  },

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass('safe-area-' + __props.area),
        style: _normalizeStyle({
          background: __props.bgColor
        })
      }, null, 6);
    };
  }

};