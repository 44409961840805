import request from '@/utils/request'

/**
 * 演示
 */
// export function getUrl() {
//   return request({
//     method: 'GET',
//     url: '/url'
//   })
// }

// export function sendCode(code) {
//   return request({
//     method: 'GET',
//     url: '/sendCode',
//     params: {
//       code
//     }
//   })
// }

/**
 * 获取公众号信息
 */
export function getAccountMsg(path) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/account`
  })
}

/**
 * 获取微信公众号openid和当前用户的权限信息(有手机号)
 */
export function getAccountAuth(path, code) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/handle/${path}/oauth2`,
    params: {
      code
    }
  })
}

/**
 * 获取微信用户信息
 */
export function getUserInfo(path, openid) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/info/${openid}`
  })
}

/**
 * 得到当前用户存储的微信信息
 */
// export function getUserWxMsg(path, openid) {
//   return request({
//     method: 'GET',
//     url: `/yia/v1/wx/user/${path}/info/${openid}`
//   })
// }

/**
 * 得到当前用户已经绑定的患者
 */
export function getCurrentBindPatient(path, openid) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/list-patients/${openid}`
  })
}

/**
 * 切换最后绑定用户
 */
export function switchLastBindUser(path, openid, patient, clinicId) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/switch/${openid}`,
    params: {
      patient,
      clinicId
    }
  })
}

/**
 * 当前用户解绑诊所
 */
export function unBindClinic(path, patientId, openid) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/client/${path}/del-rel/${patientId}?openid=${openid}`
  })
}

/**
 * 绑定手机操作
//  */
// export function bindMobile(path, openid, mobile, code) {
//   return request({
//     method: 'GET',
//     url: `/yia/v1/wx/user/${path}/bindMobile/${openid}?mobile=${mobile}&code=${code}`
//   })
// }

/**
 * 获取oss图像路径
 * @param {*} path 公众号path
 */
export function getOssImageUrl(path) {
  return request({
    method: 'GET',
    url: `/sys/v1/file/exchange?path=${path}`
  })
}

/**
 * 微信公众号网页登录
 * @param {*} mobile 手机号随机数
 */
export function wxPhoneLogin(mobile, openid) {
  return request({
    method: 'POST',
    auth: {
      username: 'app',
      password: 'app'
    },
    url: `/sys/wx/phone/token?mobile=${mobile}&openid=${openid}`
  })
}

/**
 * 发送验证码
 * @param {*} mobile 手机号
 */
export function sendCode(path, mobile) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/sendSms?mobile=${mobile}`
  })
}

/**
 * 校验手机号和验证码是否正确
 * @param {*} mobile 手机号
 * @param {*} code 验证码
 * @returns Promise<>
 */
export function verifyMobile(path, mobile, code) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/verifyMobile?mobile=${mobile}&code=${code}`
  })
}

/**
 *
 * @param {*} mobile 手机号
 * @param {*} code 验证码
 * @returns Promise<>
 */
export function bindMobile(path, mobile, code) {
  return request({
    method: 'GET',
    url: `/yia/v1/wx/user/${path}/bindMobile?mobile=${mobile}&code=${code}`
  })
}
