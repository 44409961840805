import {
  getAccountAuth,
  getAccountMsg,
  getCurrentBindPatient,
  getUserInfo,
  wxPhoneLogin
} from '@/api/app'
import router from '@/router'
import { Dialog } from 'vant'
export default {
  namespaced: true,
  state: () => {
    return {
      accountData: {},
      userMessage: {},
      userInfo: {},
      bindPatientList: [],
      code: '',
      token: {}
    }
  },
  mutations: {
    changeAccountData(state, payload) {
      state.accountData = payload || {}
    },

    changeUserMessage(state, payload) {
      state.userMessage = payload || {}
    },

    changeUserInfo(state, payload) {
      state.userInfo = payload || {}
    },
    changeBindPatientList(state, payload) {
      state.bindPatientList = payload || []
    },

    changeCode(state, payload) {
      state.code = payload
    },

    changeToken(state, payload) {
      state.token = payload || {}
    },

    clearState(state) {
      state.accountData = {}
      state.userMessage = {}
      state.userInfo = {}
      state.bindPatientList = []
      state.code = ''
      state.token = ''
      localStorage.clear()
    }
  },
  actions: {
    async getAccountData({ commit }, payload) {
      const res = await getAccountMsg(payload)
      commit('changeAccountData', res.data || {})
    },

    async getAccountAuthData({ commit, dispatch }, { path, code }) {
      const res = await getAccountAuth(path, code)
      commit('changeUserMessage', res.data || {})
      commit('changeToken', {})
      if (res.data.randomNumber && res.data.mobile) {
        await dispatch('_wxPhoneLogin', res.data.randomNumber)
      } else {
        if (router.currentRoute.value.path.includes('my/bind')) return
        Dialog.confirm({
          title: '绑定手机号',
          message: '为了提供更好的服务和保护您的账号安全，请绑定您的手机号。',
          confirmButtonText: '去绑定',
          cancelButtonText: '暂不绑定'
        })
          .then(() => {
            router.push({
              path: `/my/bind/${router.currentRoute.value.params.path}`,
              query: {
                returnUrl: router.currentRoute.value.fullPath
              }
            })
          })
          .catch(() => {})
      }
    },

    async _wxPhoneLogin({ commit, state }, randomNumber) {
      const token = await wxPhoneLogin(randomNumber, state.userMessage.openid)
      commit('changeToken', token)
    },

    async getUserInfo({ commit }, { path, openid }) {
      const res = await getUserInfo(path, openid)
      commit('changeUserInfo', res.data || {})
    },

    async getBindPatientList({ commit }, { path, openid }) {
      const res = await getCurrentBindPatient(path, openid)
      commit('changeBindPatientList', res.data || [])
    }
  }
}
