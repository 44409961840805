import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-250a2058"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  id: "app"
};
const _hoisted_2 = {
  key: 1
};
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import usePatientInfo from '@/common/usePatientInfo';
import useCode from '@/common/useCode';
import homeSkeleton from '@/components/home-skeleton/index.vue';
export default {
  __name: 'App',

  setup(__props) {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const loading = ref(true);

    const init = async () => {
      await router.isReady();
      await useCode({
        router,
        store,
        route
      });
      const loadDataLoadingRes = await usePatientInfo({
        router,
        store,
        route
      });
      loading.value = loadDataLoadingRes;
    };

    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [_createVNode(_Transition, {
          mode: "out-in",
          "enter-active-class": "animate__animated animate__fadeIn"
        }, {
          default: _withCtx(() => [loading.value ? (_openBlock(), _createBlock(homeSkeleton, {
            key: 0
          })) : (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))]))]),
          _: 2
        }, 1024)]),
        _: 1
      })]);
    };
  }

};