import { isEmpty } from '@/utils'

export default {
  accountData: (state) => state.app.accountData,
  userMessage: (state) => state.app.userMessage,
  userInfo: (state) => state.app.userInfo,
  bindPatientList: (state) => state.app.bindPatientList,
  code: (state) => state.app.code,
  token: (state) => state.app.token,
  currentBindPatient: (state) => {
    if (state.app.userInfo.lastPatientId && state.app.bindPatientList.length) {
      const value = state.app.bindPatientList.find(
        (item) => item.patient === state.app.userInfo.lastPatientId
      )

      if (!value || isEmpty(value)) {
        return state.app.bindPatientList[0]
      } else {
        return value
      }
    } else if (
      !state.app.userInfo.lastPatientId &&
      state.app.bindPatientList.length
    ) {
      return state.app.bindPatientList[0]
    }
    return {}
  },
  clinicId: (state, getters) => {
    return getters.currentBindPatient.clinicId || ''
  },
  patientId: (state, getters) => {
    return getters.currentBindPatient.patient || ''
  }
}
