import 'vant/es/dialog/style'

const usePatientInfo = async ({ router, store, route }) => {
  await router.isReady()
  const searchParams = new URLSearchParams(window.location.search)
  const code = searchParams.get('code')

  if (code && !window.history.state.forward?.includes('scoreredirect')) {
    if (localStorage.getItem('mobile') || route.fullPath.includes('my/bind')) {
      return false
    }

    // code存在表示是页面A2
    // history.pushState({ page: 1 }, null, window.location.href)

    const { path } = route.params

    await store.dispatch('app/getAccountData', path)

    await store.dispatch('app/getAccountAuthData', { path, code })

    await store.dispatch('app/getUserInfo', {
      path,
      openid: store.getters.userMessage.openid
    })

    await store.dispatch('app/getBindPatientList', {
      path,
      openid: store.getters.userMessage.openid
    })

    return false
  }

  if (window.history.state.forward?.includes('scoreredirect')) {
    return false
  }

  if (route.fullPath.includes('image')) {
    return false
  }
  return true
}

export default usePatientInfo
