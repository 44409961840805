import router from '@/router'
import store from '@/store'
import { Toast } from 'vant'
// import { isEmpty } from './utils'

router.beforeEach(async (to) => {
  if (to.fullPath.includes('/image')) {
    return
  }

  // if (
  //   to.path.includes('/appointment/add') &&
  //   isEmpty(store.getters.currentBindPatient)
  // ) {
  //   Toast({
  //     message: '请先绑定用户',
  //     type: 'fail'
  //   })
  //   return router.push(from.fullPath)
  // }

  if (store.getters.userInfo.mobile && to.fullPath.includes('/my/bind')) {
    setTimeout(() => {
      Toast({
        message: '已绑定手机号',
        type: 'text',
        duration: 1500
      })
    })
    return router.push(`/home/${to.params.path}`)
  }
  if (store.getters.userMessage?.openid && to.meta.title !== '个人中心') {
    return
  }
  return
})

router.afterEach((to) => {
  document.title = to.meta.title
})
