import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import 'normalize.css'
import '@/assets/animate.min.css'
import '@/css/index.css'
import { Toast } from 'vant'
import 'vant/es/toast/style'
// 导入 svgIcon
import installIcons from '@/icons'
import '@/permission'

const app = createApp(App)

app.use(Toast)
installIcons(app)
app.use(store).use(router).mount('#app')
