import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
export default {
  __name: 'index',
  props: {
    height: [String, Number],
    width: [String, Number],
    circle: {
      type: Boolean,
      default: false
    },
    selfClass: {
      type: String,
      default: ''
    }
  },

  setup(__props) {
    const props = __props; // eslint-disable-next-line

    const varStyle = computed(() => ({
      height: props.height + 'px',
      width: props.width + 'px'
    })); // eslint-disable-next-line

    const varClass = computed(() => `${props.selfClass}`);
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        style: _normalizeStyle([_unref(varStyle), {
          "background-color": "#f1f1f1"
        }]),
        class: _normalizeClass(["skeleton", _unref(varClass)])
      }, null, 6);
    };
  }

};