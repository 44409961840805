import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-cf2c13aa"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_4 = {
  class: "menu-list"
};
const _hoisted_5 = {
  class: "bottom-menu"
};
const _hoisted_6 = {
  class: "bottom-menu-box"
};
import 'vue';
import iSkeleton from '../i-skeleton/index';
import iSafeItem from '../safe-area/index.vue';
export default {
  __name: 'index',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createVNode(_unref(iSkeleton), {
        class: "avatar"
      })]), _createElementVNode("div", _hoisted_3, [_createVNode(_unref(iSkeleton), {
        class: "top-box"
      }), _createVNode(_unref(iSkeleton), {
        class: "name"
      }), _createVNode(_unref(iSkeleton), {
        class: "bottom-box"
      })]), _createVNode(_unref(iSkeleton), {
        class: "switch"
      })]), _createVNode(_unref(iSkeleton), {
        class: "nav-list"
      }), _createElementVNode("div", _hoisted_4, [_createVNode(_unref(iSkeleton), {
        class: "menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "menu-item"
      })]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createVNode(_unref(iSkeleton), {
        class: "bottom-menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "bottom-menu-item"
      }), _createVNode(_unref(iSkeleton), {
        class: "bottom-menu-item"
      })]), _createVNode(iSafeItem)])]);
    };
  }

};